<template>
  <div class="flex-container">
    <div class="content">
      <h1 class="title is-2">Klaar om wiskunde te oefenen?</h1>
      <p>Kies je leerjaar en niveau om gelijk aan de slag te gaan. Er zijn nog niet voor alle niveaus en leerjaren opdrachten beschikbaar.</p>
      <div class="columns">
        <div class="column">
          <b-field label="Leerjaar">
            <b-select @input="getAvailableLevels()" size="is-medium" v-model="year" expanded>
              <option v-for="year in available.years" :value="year.math_question_year">{{ year.math_question_year }}</option>
            </b-select>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Opleiding">
            <b-select @input="getAvailableTopics()" size="is-medium" expanded v-model="level">
              <option v-for="level in available.levels" :value="level.math_question_level">{{ level.math_question_level | ucfirst }}</option>
            </b-select>
          </b-field>
        </div>
        <div class="column" v-if="available.topics.length > 0">
          <b-field label="Onderwerp">
            <b-select size="is-medium" expanded v-model="topic">
              <option v-for="topic in available.topics" :value="topic.math_question_topic">{{ topic.math_question_topic | ucfirst }}</option>
            </b-select>
          </b-field>
        </div>
      </div>
      <b-button @click="start()" :disabled="!fullSelect" type="is-primary">Start!</b-button>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import router from "@/router";

export default {
  name: "dashboard",
  components: {},
  data() {
    return {
      available: {
        years: [],
        levels: [],
        topics: []
      },
      year: null,
      level: null,
      topic: null
    };
  },
  mounted() {
    this.getAvailableYears();
    this.getAvailableLevels();
  },
  methods: {
    async getAvailableYears() {
      let response = await ApiService.get('math/availability/years', true);
      this.available.years = response.data;
    },
    async getAvailableLevels() {
      let response = await ApiService.get(`math/availability/levels/${this.year}`, true);
      this.available.levels = response.data;
      this.available.topics = [];
      if (this.level !== null) {
        this.getAvailableTopics();
      }
    },
    async getAvailableTopics() {
      let response = await ApiService.get(`math/availability/topics/${this.year}/${this.level}`, true);
      this.available.topics = response.data;
    },
    async start() {
      router.push(`/test/${this.year}/${this.level}/${this.topic}`);
    }
  },
  computed: {
    fullSelect() {
      return this.year !== null && this.level !== null & this.topic !== null;
    }
  }
};
</script>
<style lang="scss" scoped>
.flex-container {
  height: 100vh;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  margin-top: -5%;
}
.content {
  padding: 2%;
}
</style>